import { User } from 'firebase/auth';
import { useState } from 'react';
import logger from "../../utils/logger";
import { createHeadersForApiCall } from '../../utils/api-utils';
import { ChColumn } from '../schema-contracts';

interface AIQueryViewProps {
  user: User;
  columns: ChColumn[];
  onReplaceAndRun: (query: string) => void;
  onReplace: (query: string) => void;
}

interface PromptSuggestionProps {
  text: string;
  onClick: (string) => void;
}

const PromptSuggestion: React.FC<PromptSuggestionProps> = ({ text, onClick }) => (
  <span className='bg-[#F4F2F069] mr-6 hover:bg-gray-200 border border-[#747070] rounded-lg block my-1 p-2 cursor-pointer' onClick={() => onClick(text)}>
    {text}
  </span>
);

const InitialPromptSuggestions: React.FC<{ onSend: (text: string) => void }> = ({ onSend }) => (
  <>
    <PromptSuggestion text="Last 50 error logs" onClick={(text) => onSend(text)} />
    <PromptSuggestion text="20 most visited pages in the last 24 hours in a piechart" onClick={(text) => onSend(text)} />
    <PromptSuggestion text="Show log count in the last week in a timechart" onClick={(text) => onSend(text)} />
  </>
);

const AfterQueryShortcutSuggestions: React.FC<{ onSend: (text: string) => void }> = ({ onSend }) => (
  <>
    <PromptSuggestion text="Enter 'rr' to set suggested query and run " onClick={() => onSend('rr')} />
    <PromptSuggestion text="Enter 'r' to set suggested query without running" onClick={() => onSend('r')} />
    <PromptSuggestion text="Enter 'c' to copy suggested query to clipboard " onClick={() => onSend('c')} />
  </>
);

const AIQueryView = ({ user, columns, onReplace, onReplaceAndRun }: AIQueryViewProps) => {
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Hello! How can I help you today?", isQuery: false },
  ]);
  const [userInput, setUserInput] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const handleSend = async (overrideUserInput: string = undefined) => {
    const input = overrideUserInput || userInput;
    console.log('handleSend called. input=' + input);
    if (input.trim() === "") return;

    const lastQueryText = (messages.filter(x => x.isQuery).slice(-1)[0])?.text; // last query
    setMessages([...messages, { sender: "user", text: input, isQuery: false }]);
    logger.info(JSON.stringify({eventName: "AIPrmopt", prompt:input  }), 'j0vhd');
    setUserInput("");

    try {
      if (lastQueryText) {
        if (input === 'rr') {
          onReplaceAndRun(lastQueryText);
          return;
        } else if (input === 'r') {
          onReplace(lastQueryText);
          return;
        } else if (input === 'c') {
          navigator.clipboard.writeText(lastQueryText);
          return;
        }
      }

      setInProgress(true);
      const isLocalhost = window.location.hostname === 'localhost'; // UNCOMMENT DURING DEBUG IF NEEDED 
      const url = isLocalhost ? 'http://localhost:8000/api/ai-query-prompt' : 'https://obics.io/api/ai-query-prompt/';
      const headers = await createHeadersForApiCall(user, logger);
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ prompt: input, columns }),
        headers,
      });
      const jsonResponse = await res.json();
      let actualResponse = jsonResponse.response as string;

      const isQuery = actualResponse.startsWith("```");
      actualResponse = isQuery ? actualResponse.substring(3, actualResponse.length - 3) : actualResponse;
      actualResponse = actualResponse.startsWith("sql") ? actualResponse.substring(3) : actualResponse;
      actualResponse = actualResponse.trim();

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: actualResponse, isQuery },
      ]);

      
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Sorry, I'm unable to process your request. Error: " + error?.toString(), isQuery: false },
      ]);
    } finally {
      setInProgress(false);
      // scroll message container to bottom
      setTimeout(() => {
        const messageContainer = document.getElementById("message-container");
        messageContainer.scrollTop = messageContainer.scrollHeight;
        
        const promptInput = document.getElementById("prompt-input");
        promptInput.focus();
      }, 100);
    }


  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  }



  return (
    <div className="flex flex-col h-full bg-transparent ">
      
      {/* Messages Container */}
      <div id="message-container" className="flex-1 overflow-y-auto border border-[#E8E8E8] rounded-2xl p-4 space-y-4 ">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"
              }`}
          >
            <div className={`max-w-xs px-4 py-2 ${msg.sender === "user"
                  ? "bg-bgdark1 text-white rounded-t-xl rounded-bl-xl"
                  : "bg-[#F4F2F085] text-black rounded-t-xl rounded-br-xl"
                  }`}>
            {msg.text}
            </div>
            
          </div>
        ))}
        {inProgress && <div className='spinner2' />}
      </div>

      <div id="prompt-suggestions" className='mt-4 mb-2'>
        {
          messages[messages.length - 1].isQuery ? <AfterQueryShortcutSuggestions onSend={handleSend} /> :
            <InitialPromptSuggestions onSend={handleSend} />
        }
      </div>

      {/* Input Area */}
      <div className="mt-4 flex items-center gap-2 border border-gray-300 rounded-lg py-[2px] px-1">
        <input
          id="prompt-input"
          type="text"
          className="flex-1 px-4 shadow-sm focus:outline-none "
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyUp={handleKeyUp}
        />
        <button
          onClick={() => handleSend()}
          className="w-[40px] h-[40px] bg-bgdark2 text-white rounded-lg shadow hover:bg-black transition flex items-center justify-center"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5407 4.96418L5.54741 2.46752C2.19324 0.787517 0.816576 2.16418 2.49658 5.51835L3.00408 6.53335C3.14991 6.83085 3.14991 7.17502 3.00408 7.47252L2.49658 8.48168C0.816576 11.8359 2.18741 13.2125 5.54741 11.5325L10.5407 9.03585C12.7807 7.91585 12.7807 6.08418 10.5407 4.96418ZM8.65658 7.43752H5.50658C5.26741 7.43752 5.06908 7.23918 5.06908 7.00002C5.06908 6.76085 5.26741 6.56252 5.50658 6.56252H8.65658C8.89574 6.56252 9.09408 6.76085 9.09408 7.00002C9.09408 7.23918 8.89574 7.43752 8.65658 7.43752Z" fill="white" />
          </svg>

        </button>
      </div>
    </div>
  );
};

export default AIQueryView;

