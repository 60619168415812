// import DropdownMenu from "./DropDownMenu"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface SidebarLinkProps {
  active: boolean;
  enabled: boolean;
  icon: IconDefinition;
  link: string;
  text: string;
}

const SidebarLink = ({ icon, link, text, active, enabled }: SidebarLinkProps) => {

  return (
      <div onClick={() => {  if (enabled) {window.location.href=link} }}  
          className={'px-[13px] py-[16px] rounded-2xl' 
            + (active && " bg-[#3B3E42]") + (enabled ? " cursor-pointer text-white" : " text-[#929496]")} > 
        <div className="w-[35px] inline-block">
          <FontAwesomeIcon icon={icon} />
        </div>
        {/* <span className="icon">{icon}</span> */}
        <span className="sidebar-link-text">{text}</span>
      </div>
      
      
  );
}

export default SidebarLink
