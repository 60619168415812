import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge4 = (() => {



  return (
    <div className="challenge-inner-container">
      
      {/* TODO: The idea here is to correlate an error to an earlier log. For that, we need better errors */}
      <p>Piecharts are really handy to visualize distribution. For example, let's show the distribution of the <strong>level</strong> field in all our logs.</p>
        
            
        <ChallengeSolution solutionQuery="SELECT count(*) as Times, level FROM Logs 
GROUP BY level
VISUALIZE PIECHART"
/>
<p>You can visualize a piechart by choosing "Piechart" in the options button near "Run" on top of the page.</p>
<p><strong>Bonus: </strong>Try extracting the status code from Error logs and show the distribution of status codes in a piechart.</p>

<ChallengeSolution solutionQuery="SELECT Count(*) as times,  regexp_extract(message, 'statusCode=([^\s]+)') AS statusCode FROM Logs 
WHERE level = 5
GROUP BY 2
VISUALIZE PIECHART"
/>

    </div>
  );
});

export default Challenge4;
