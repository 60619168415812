import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge3 = (() => {



  return (
    <div className="challenge-inner-container">
      {/* TODO: The idea here is to correlate an error to an earlier log. For that, we need better errors */}
      <p>Obics analytics engine can do aggregations in real-time for huge data sets. 
            One of the most common use cases is to track the number of page views over time. Let's create a timechart that shows the number of page views per day.
            Hint: The <strong>logId</strong> that describes a page view is <strong>apag</strong></p>
            
        <ChallengeSolution solutionQuery="SELECT  toStartOfDay(time) AS date,
 COUNT(*) AS times
FROM Logs
WHERE logId = 'apag'
GROUP BY date
VISUALIZE TIMECHART"
/>
<p>You can visualize a timechart by finishing your query with `VISUALIZE Timechart` or choosing "Timechart" in the "Display as" options.</p>

    </div>
  );
});

export default Challenge3;
