import { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { isSandboxHostname } from '../utils/url-utils';
import logger from '../utils/logger';
import logo from './logo-small-on-white.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // check if current url is localhost

  // get the "isSandbox" query parameter
  if (isSandboxHostname()) {
    logger.info('This is a sandbox environment, logging in with sandbox user', 'qCWNr');
    signInWithEmailAndPassword(auth, 'sandbox@idlog.io', '5zEJp71c9NFwpQ');
    // remove query parameter from URL
    window.history.replaceState({}, document.title, '/');
    return <div></div>;
  }


  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      logger.info("handleLogin: User logged in! " + email, 'osw2w');//  + email + "auth" + auth + "password" + password);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div>
        <img
          className="mx-auto mb-10"
          src={logo}
          width={151}
          alt="Obics logo"
        />
        <form
          className="w-[350px] text-center bg-white pb-10 pl-10 pr-10 pt-5 rounded shadow-lg"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            handleLogin(); // Call the login handler
          }}
        >
          <h1 className="text-dark5 font-semibold text-2xl mb-4">Sign in</h1>

          <div className="mb-4 text-left">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="mb-4 text-left">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <button
            type="submit"
            className="w-full mt-3 bg-dark5 text-white px-6 py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400"
          >
            Continue
          </button>
        </form>

      </div>
    </div>
  );
};

export default Login;
