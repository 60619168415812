import React, { useEffect, useRef, useState } from 'react';
import './query-tabs.css';
import Query from './query';
import { User } from 'firebase/auth';
import logger from '../utils/logger';
import { ChColumn, ChTable } from './schema-contracts';
import QuerySidepane from './sidepane/query-sidepane';
import TablesTreeView from './sidepane/tables-tree-view';
import AIQueryView from './sidepane/ai-query-view';
import { PiFileSqlDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

interface QueryTabProps {
  user: User;
}

const QuerySidepaneWidth = 70;
const TableTreeviewWidth = 267;
const AIQueryViewWidth = 450;

const QueryTabs: React.FC<QueryTabProps> = ({ user }) => {

  logger.info(`QueryTabs user: ${user}`, 'xeyot');

  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [chSchema, setChSchema] = useState<ChTable[]>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isTableTreeviewOpen, setIsTableTreeviewOpen] = useState(false);
  const [isAIQueryViewOpen, setIsAIQueryViewOpen] = useState(true);
  const [tabList, setTabList] = useState([
    { label: 'Tab 1' },
  ]);

  const editorRef = useRef({});

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter' && !isCtrlPressed) {
        setIsCtrlPressed(true);
        runQueryForActiveTab();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    fetchClickHouseSchema(user).then((schema1) => {
      setChSchema(schema1);
      logger.info('Got ClickHouse schema. tables: ' + schema1?.map(t => t.name).join(','), 'q90g7');
    }).catch((error) => {
      logger.error('Error fetching schema: ' + (error && typeof error.toString === 'function' && error.toString()), 'EmBvh');
    });


    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const fetchClickHouseSchema = async (user: User): Promise<ChTable[]> => {
    const reallyLocalhost = false;// UNCOMMENT DURING DEBUG IF NEEDED window.location.hostname === 'localhost';
    logger.info('fetchClickHouseSchema starting to fetch', 'X6gnA');
    const queryApiUrl = reallyLocalhost ? 'http://localhost:8000/api/schema' : 'https://obics.io/api/schema/';
    const response = await fetch(queryApiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "uid": user.uid,
        "Authorization": "Bearer " + await user.getIdToken(),
        'x-session-id': logger.getSessionId(),
      }
    });
    return (await response.json()) as ChTable[];
  }



  const runQueryForActiveTab = () => {
    tabList.forEach((tab, index) => {
      editorRef.current[index].callRunQueryIfActive();
    });
  };

  // Function to switch tabs
  const handleTabClick = (index) => {
    logger.info('Tab clicked:' + index, '8vccu');
    setActiveTabIndex(index);

  };

  const handleNewTab = () => {
    logger.info('New Tab clicked', 'oSgB7');
    const numTabs = tabList.length;
    const newTabList = tabList.slice();
    newTabList.push({ label: `Tab ${numTabs + 1}` });
    setTabList(newTabList);
    setActiveTabIndex(numTabs);
  }

  function handleCloseTab(event, index: number): void {
    logger.info('handleCloseTab: active index:' + activeTabIndex + ' close index: ' + index, '9w3JU');
    event.stopPropagation();

    const newTabList = tabList.slice();
    newTabList.splice(index, 1);
    setTabList(newTabList);
    const newActiveTabIndex = activeTabIndex === index ? 0 : activeTabIndex < index ? activeTabIndex : activeTabIndex - 1;
    setActiveTabIndex(newActiveTabIndex);
  }

  const toggleTableTreeview = () => {
    setIsAIQueryViewOpen(false);
    setIsTableTreeviewOpen(!isTableTreeviewOpen);
  };

  const toggleAIQueryView = () => {
    setIsTableTreeviewOpen(false);
    setIsAIQueryViewOpen(!isAIQueryViewOpen);
  };

  

  function getColumns(): ChColumn[] {
    const logsTable = chSchema?.find(t => t.name === 'Logs');
    if (!logsTable) {
      return [];
    }
    return logsTable.columns.map(c => ({ name: c.name, type: c.type }));
  }

  function onReplaceQueryWithNewValue(newQuery: string) {
    // editorRef.current[activeTabIndex].callSetTableVisualization();
    editorRef.current[activeTabIndex].callSetValue(newQuery);
  }

  function onReplaceQueryWithNewValueAndRun(newQuery: string) {
    // editorRef.current[activeTabIndex].callSetTableVisualization();
    onReplaceQueryWithNewValue(newQuery);
    editorRef.current[activeTabIndex].callRunQueryIfActive(true /* set table */);
  }

  return (
    <div id="query-tabs" className='flex flex-1'> {/*green background bg-[#005500]*/}
      
      <div className="generic-sidepane-container" style={{width: QuerySidepaneWidth, overflowX: 'clip'}}>
        <QuerySidepane onTablesClick={toggleTableTreeview} isTableTreeviewOpen={isTableTreeviewOpen} onAIAssistantClick={toggleAIQueryView} isAIQueryViewOpen={isAIQueryViewOpen}/>
      </div>
      {/* Tables view */}
      <div className="generic-sidepane-container fast-transition p-1"
        style={{ width: isTableTreeviewOpen ? TableTreeviewWidth : 0, display: isTableTreeviewOpen ? 'block' : 'none' }}>
        <TablesTreeView tables={chSchema} />
      </div>
      {/* AI Query assistant View */}
      <div className="generic-sidepane-container fast-transition p-1"
        style={{ width: isAIQueryViewOpen ? AIQueryViewWidth : 0, display: isAIQueryViewOpen ? 'block' : 'none' }}>
        <AIQueryView user={user} columns={getColumns()} onReplace={onReplaceQueryWithNewValue} onReplaceAndRun={onReplaceQueryWithNewValueAndRun} />
      </div>

      <div className='tab-container fast-transition flex-1 flex flex-col' >
        {/* Tab Labels */}
        <div className="tabs">
          {tabList.map((tab, index) => (
            <button key={index} className={'relative group' + (activeTabIndex === index ? ' active' : '') + ' tab-button' + (index === 0 ? '' : ' border-l-[1px] border-l-gray-200')} 
                onClick={() => handleTabClick(index)}>
              <PiFileSqlDuotone className='mr-2'  />
              <span className='tab-button-text text-sm mr-2'>{tab.label}</span>
              <IoClose className='block opacity-0 group-hover:opacity-100 transition-opacity and duration-200 hover:bg-gray-300 rounded-lg'
                    onClick={(ev) => handleCloseTab(ev, index)}/>
              {/* <button className='close-tab-btn' onClick={(ev) => handleCloseTab(ev, index)}>x</button> */}
            </button>
          ))}
          <button
            className='hover:bg-gray-200 text-[#333] cursor-pointer py-0 px-[10px]  border-l-[#DDDBDA]'
            onClick={() => handleNewTab()}
          >+</button>
        </div>

        {/* Tab Content - Render the component of the active tab */}
        <div className="tab-content flex-1">
          {/* {tabList[activeTabIndex].component} */}
          {tabList.map((tab, index) => (
            <div
              key={index}
              style={{ height: '100%', display: activeTabIndex === index ? 'block' : 'none' }}
            >
              
              <Query index={index} isActive={index === activeTabIndex} chSchema={chSchema} ref={ref => editorRef.current[index] = ref} ctrlEnterClicked={runQueryForActiveTab} user={user} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QueryTabs;

