import { User } from "firebase/auth";
import { Logger } from "./logger";

export async function createHeadersForApiCall (user: User, logger: Logger = undefined) {
  return {
    'Content-Type': 'application/json',
    "uid": user.uid,
    "Authorization": "Bearer " + await user.getIdToken(),
    'x-session-id': logger?.getSessionId(),
  }
}