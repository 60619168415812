import * as React from "react"
import { useRef, useState, useImperativeHandle, forwardRef } from "react";

import MonacoEditor from "./monaco-editor"
import '../common.css';
import './query.css';
import Timechart from "./timechart";
import Table from "./table";
import Piechart from "./piechart";
import { User } from "firebase/auth";
import { isSandboxUser as checkIsSandboxUser } from "../utils/url-utils";
import logger from "../utils/logger";
import { ChTable } from "./schema-contracts";
import { createHeadersForApiCall } from "../utils/api-utils";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

enum VisualizationType {
  TABLE = 'table',
  TIMECHART = 'timechart',
  PIE = 'pie',
}

type ViewState = "Normal" | "QueryMaximized" | "ResultsMaximized";

interface QueryProps {
  index: number;
  isActive: boolean;
  ctrlEnterClicked: () => void;
  user: User;
  chSchema: ChTable[];
}

const SPLITTER_HEIGHT = 1;
const STATUS_BAR_HEIGHT = 30;
const Query = forwardRef((props: QueryProps, ref) => {

  const editorRef = useRef(null);
  const tableRef = useRef(null);
  const timechartRef = useRef(null);
  const piechartRef = useRef(null);
  const [isRunDisabled, setIsRunDisabled] = useState(false);
  const [visualizationType, setVisualizationType] = useState(VisualizationType.TABLE);
  const [results, setResults] = useState([]);
  const [viewState, setViewState] = useState<ViewState>("QueryMaximized");
  const [lastQueryDuration, setLastQueryDuration] = useState(undefined);

  useImperativeHandle(ref, () => ({
    callSetValue: (value) => editorRef.current.callSetValue(value),
    callRunQuery: onRunQuery.bind(this),
    callRunQueryIfActive: (setTable: boolean = false) => onRunQuery(setTable && VisualizationType.TABLE),
  }));



  const onRunQuery = (overrideVisualizationType: VisualizationType = undefined) => {
    logger.info('onRunQuery tabIndex ' + props.index + ' tabActive: ' + props.isActive, "2BVGf");
    if (!props.isActive) {
      return;
    }

    if (isRunDisabled) {
      return;
    }

    const queryCodeInitial = editorRef.current.callGetValue();
    // eslint-disable-next-line prefer-const
    let [queryCode, newVisualizationType] = processQueryCode(queryCodeInitial);

    if (!newVisualizationType) {
      newVisualizationType = visualizationType;
      if (overrideVisualizationType) {
        newVisualizationType = overrideVisualizationType;
      }
    }

    setIsRunDisabled(true);
    if (sandboxUser) {
      logger.info('onRunQuery: query: ' + queryCode, "vxUx7");
    }

    createHeadersForApiCall(props.user, logger).then(headers => {
      logger.debug(`onRunQuery: uid: ${headers.uid}`, "6x8T2");
      const localhost = true; // Change during DEBUG
      const reallyLocalhost = localhost && window.location.hostname === 'localhost';
      const queryApiUrl = reallyLocalhost ? 'http://localhost:8000/api/query' : 'https://obics.io/api/query/';
      fetch(queryApiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          "query": queryCode,
        })
      })
        .then(response => response.json()) // Parse the response as JSON
        .then(resp => {

          if (resp.error) {
            updateErrorResult(resp.error);
          } else {
            const data = resp.response.data;

            setLastQueryDuration(resp.duration);
            setVisualizationType(newVisualizationType);
            setResults(data);
            updateResults(newVisualizationType, data);
          }
        })
        .catch((error) => {
          console.log('!!! error' + (error?.toString()));
          logger.error('Error in query POST request: ' + (error?.toString()), "6x8T7");
          updateErrorResult(error);
        }).finally(() => {
          setIsRunDisabled(false);
          if (viewState === "QueryMaximized") {
            onExpandResults();
          }
        });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateErrorResult(error: any) {
    const errorObj = {
      error: error,
    };
    const errorResponse = [errorObj];

    setVisualizationType(VisualizationType.TABLE);
    setResults(errorResponse);
    updateResults(VisualizationType.TABLE, errorResponse);
  }

  function updateResults(newVisualizationType, newResults = undefined) {
    logger.info('updateResults called. visualization: ' + VisualizationType[newVisualizationType], "6x8T3");
    const visType = newVisualizationType ?? visualizationType;
    if (visType === VisualizationType.TABLE) {
      tableRef.current.callUpdateTable(newResults ?? results);
    } else if (visType === VisualizationType.TIMECHART) {
      timechartRef.current.callUpdateResults(newResults ?? results);
    } else if (visType === VisualizationType.PIE) {
      piechartRef.current.callUpdateResults(newResults ?? results);
    }
  }

  const handleVisualizationChange = (event) => {
    logger.debug(`handleVisualizationChange ${event.target.value}`, "6x8T4");
    setVisualizationType(event.target.value);
    updateResults(event.target.value);
  };

  const [topHeight, setTopHeight] = useState(40); // Initial height as 40% for sql and 60% for results
  const sandboxUser = checkIsSandboxUser(props.user);
  const [isResizing, setIsResizing] = useState(false);
  const resizingRef = useRef(isResizing);

  React.useEffect(() => {
    // Keep the ref updated whenever isMouseDown changes
    resizingRef.current = isResizing;
  }, [isResizing]);

  const handleSplitterMouseDown = () => {
    if (viewState !== "Normal") {
      return;
    }

    logger.debug('handleSplitterMouseDown, starting resize', "6x8T5");
    setIsResizing(true);
    document.body.style.cursor = 'row-resize';
  };

  const handleMouseMove = (e) => {
    if (resizingRef.current) {
      // Calculate new height as a percentage of the container
      const containerRect = document.querySelector('.editor-and-results').getBoundingClientRect();
      const relativeY = e.clientY - containerRect.top;
      const newTopHeight = (relativeY / (containerRect.height - SPLITTER_HEIGHT)) * 100;

      // Limit panel resizing within 10% and 90% of the container height
      if (newTopHeight >= 10 && newTopHeight <= 90) {
        setTopHeight(newTopHeight);
      }
    }
  };

  const handleMouseUp = () => {
    if (resizingRef.current) {
      logger.debug('handleMouseUp, stopping resize', "6x8T6");
      setIsResizing(false);
      document.body.style.cursor = 'default';
      reLayoutMonaco();
    }
  };
  
  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove.bind(this));
    document.addEventListener('mouseup', handleMouseUp.bind(this));
    return () => {
      document.removeEventListener('mousemove', handleMouseMove.bind(this));
      document.removeEventListener('mouseup', handleMouseUp.bind(this));
    };
  }, []);

  function reLayoutMonaco(extraLayout = false) {
    editorRef.current.callLayout();
    setTimeout(() => {
      editorRef.current.callLayout();
      if (extraLayout) {
        setTimeout(() => {
          editorRef.current.callLayout();
        }, 200);
      }
    }, 100);
  }


  const onExpandQuery = () => {
    if (viewState === "Normal") {
      setViewState("QueryMaximized");
    } else if (viewState === "ResultsMaximized") {
      setViewState("Normal");
    } else {
      return;
    }
    reLayoutMonaco(true);
  }

  const onExpandResults = () => {
    if (viewState === "Normal") {
      setViewState("ResultsMaximized");
    } else if (viewState === "QueryMaximized") {
      setViewState("Normal");
    } else {
      return;
    }
    reLayoutMonaco(true);

  }

  function getFriendlyDuration(lastQueryDuration: number | undefined): string {
    if (!lastQueryDuration) {
      return '';
    }

    if (lastQueryDuration < 10000) { // under 10 seconds
      return lastQueryDuration + 'ms';
    } else if (lastQueryDuration < 60000) { // under 1 minute
      return (lastQueryDuration / 1000).toFixed(1) + 's';
    } else {
      const minutes = (lastQueryDuration / 60000).toFixed(0);
      const seconds = ((lastQueryDuration % 60000) / 1000).toFixed(0);
      return minutes + ' minutes, ' + seconds + ' seconds';
    }
  }

  return (
    <div className="query-container bg-white">
      <div className="editor-and-results">
        {/* monaco editor */}
        <div style={{ height: viewState === "QueryMaximized" ? "100%" : topHeight.toString() + "%", display: viewState === "ResultsMaximized" ? "none" : "block" }}>
          <MonacoEditor ref={editorRef} index={props.index} isSandBox={sandboxUser} ctrlEnterClicked={() => {
            props.ctrlEnterClicked();
          }}></MonacoEditor>
        </div>


        {/* splitter */}
        <div className={"py-[2px]" + (viewState === "Normal" && " cursor-row-resize")} onMouseDown={handleSplitterMouseDown} style={{ height: SPLITTER_HEIGHT }}>
          <div className="splitter-grip h-[1px] bg-[#DDDBDA]"></div>
        </div>

        {/* Bottom panel (navbar + results) */}
        <div className="flex"
          style={{
            height: viewState === "QueryMaximized" ? "60px" : (viewState == "ResultsMaximized" ? "100%" : (100 - topHeight).toString() + "%"),
            alignItems: 'stretch',
            flexDirection: 'column'
          }}>

          {/* control navbar*/}
          <div className="flex flex-row items-center gap-[16px] h-[60px] px-[10px] justify-between">
            <div id="left-part-of-control-navbar" className="flex flex-row items-center gap-[16px]">
              <button disabled={isRunDisabled}
                className="action-button-small"
                onClick={() => onRunQuery(undefined)}
                title="Run query (Ctrl + Enter)"
              >
                Run
                {isRunDisabled ? <div className="spinner" ></div> : <span></span>}
              </button>
              <span>
                <span className="mr-2 text-[16px]">Display as:</span>
                <select onChange={handleVisualizationChange} className="outline-none border border-gray-300 rounded-2xl px-3 py-1 ">
                  <option onChange={handleVisualizationChange} selected={visualizationType === VisualizationType.TABLE} id="table" value="table">Table</option>
                  <option onChange={handleVisualizationChange} selected={visualizationType === VisualizationType.TIMECHART} id="timechart" value="timechart">Timechart</option>
                  <option onChange={handleVisualizationChange} selected={visualizationType === VisualizationType.PIE} id="pie" value="pie">Piechart</option>
                </select>
              </span>
              <a href="#" className="text-[16px] text-regular text-gray-500" title="Coming soon">Chart Options...</a>
            </div>
            <div className="flex gap-1 items-center">
              <FaChevronDown className={"inline-block mb-[2px] w-[25px] h-[25px] p-1 hover:bg-gray-200 cursor-pointer" + (viewState === "QueryMaximized" ? " text-gray-200 cursor-default" : "")}
                onClick={onExpandQuery} />
              <FaChevronUp className={"inline-block w-[25px] h-[25px] p-1 hover:bg-gray-200 cursor-pointer" + (viewState === "ResultsMaximized" ? " text-gray-200 cursor-default" : "")}
                onClick={onExpandResults} />
            </div>
          </div>

          {/* table/charts */}
          <Table ref={tableRef} visible={visualizationType === VisualizationType.TABLE && viewState != "QueryMaximized"} />
          <Timechart ref={timechartRef} visible={visualizationType === VisualizationType.TIMECHART && viewState != "QueryMaximized"} />
          <Piechart ref={piechartRef} visible={visualizationType === VisualizationType.PIE && viewState != "QueryMaximized"} />
        </div>
        
        {/* status bar */}
        <div className={`border border-t-[1px] border-b-0 border-r-0 border-l-0 border-gray-200 h-[${STATUS_BAR_HEIGHT}px] text-sm text-gray-700 bg-gray-100 flex items-center px-2`}>
        {lastQueryDuration && 
          <span className="mt-1">Query time {getFriendlyDuration(lastQueryDuration)}. {results.length} rows.</span>
          }
        </div>

      </div>



    </div>

  )
});

export default Query




function processQueryCode(queryCodeInitial: string): [queryCode: string, newVisualizationType: VisualizationType] {
  let queryCode = queryCodeInitial.trim();

  if (queryCode[queryCode.length - 1] === ';') {
    queryCode = queryCode.slice(0, queryCode.length - 1);
  }


  const words = queryCode.split(/\s+/).filter(word => word.length > 0);
  if (words.length <= 1) {
    return [queryCode, undefined /* original VisualizationType */];
  }


  const lastWord = words[words.length - 1].toLowerCase();
  const secondToLastWord = words[words.length - 2].toLowerCase();
  if (secondToLastWord === 'visualize') {
    const wordsExceptLastTwo = words.slice(0, words.length - 2);
    const queryCodeWithoutChart = wordsExceptLastTwo.join(' ');
    if (lastWord === 'timechart') {
      return [queryCodeWithoutChart, VisualizationType.TIMECHART];
    } else if (lastWord === 'piechart') {
      return [queryCodeWithoutChart, VisualizationType.PIE];
    } else if (lastWord === 'table') {
      return [queryCodeWithoutChart, VisualizationType.TABLE];
    }
  }
  return [queryCode, undefined /* original VisualizationType */];
}

