import React, { useState } from 'react';
import { CiViewTable } from "react-icons/ci";
import { GoHistory } from "react-icons/go";
import { BsCollection } from "react-icons/bs";
import { GiArtificialIntelligence } from "react-icons/gi";


const QuerySidepane = (({ onTablesClick, isTableTreeviewOpen, onAIAssistantClick, isAIQueryViewOpen }) => {


  return (
    <div className="pt-4 mx-auto flex flex-col items-center border-right-black p-1 gap-5 text-[12px] text-gray-700" >
      <div id='tables-button' title='Tables' className={"sidepane-button" + (isTableTreeviewOpen ? " sidepane-button-active" : "")} onClick={onTablesClick}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M3.24335 16.7599C2.08398 15.6005 2.08398 13.7345 2.08398 10.0026C2.08398 6.27065 2.08398 4.40468 3.24335 3.2453C4.40272 2.08594 6.2687 2.08594 10.0007 2.08594C13.7326 2.08594 15.5986 2.08594 16.758 3.2453C17.9173 4.40467 17.9173 6.27065 17.9173 10.0026C17.9173 13.7345 17.9173 15.6005 16.758 16.7599C15.5986 17.9193 13.7326 17.9193 10.0007 17.9193C6.2687 17.9193 4.40273 17.9193 3.24335 16.7599Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2.08398 7.5H17.9173" stroke-width="1.5" />
          <path d="M2.08398 12.5H17.9173" stroke-width="1.5" />
          <path d="M13.334 17.9193V2.08594" stroke-width="1.5" />
          <path d="M6.66602 17.9193V2.08594" stroke-width="1.5" />
        </svg>

      </div>
      <div title='AI Assistant' className={"sidepane-button" + (isAIQueryViewOpen ? " sidepane-button-active" : "")} onClick={onAIAssistantClick}>
        {/* <GiArtificialIntelligence size={34} /> */}
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_39_1896)">
            <path d="M3.33268 12.9193C2.41221 12.9193 1.66602 12.1731 1.66602 11.2526C1.66602 10.3321 2.41221 9.58594 3.33268 9.58594" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.666 12.9193C17.5865 12.9193 18.3327 12.1731 18.3327 11.2526C18.3327 10.3321 17.5865 9.58594 16.666 9.58594" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.83398 5.83594V3.33594" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M14.166 5.83594V3.33594" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M5.83333 3.33073C6.29357 3.33073 6.66667 2.95763 6.66667 2.4974C6.66667 2.03716 6.29357 1.66406 5.83333 1.66406C5.3731 1.66406 5 2.03716 5 2.4974C5 2.95763 5.3731 3.33073 5.83333 3.33073Z" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M14.1673 3.33073C14.6276 3.33073 15.0007 2.95763 15.0007 2.4974C15.0007 2.03716 14.6276 1.66406 14.1673 1.66406C13.7071 1.66406 13.334 2.03716 13.334 2.4974C13.334 2.95763 13.7071 3.33073 14.1673 3.33073Z" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M11.25 5.83594H8.75C6.39297 5.83594 5.21447 5.83594 4.48223 6.59342C3.75 7.3509 3.75 8.57002 3.75 11.0084C3.75 13.4467 3.75 14.6658 4.48223 15.4233C5.21447 16.1808 6.39297 16.1808 8.75 16.1808H9.60442C10.2641 16.1808 10.4968 16.317 10.9514 16.812C11.4542 17.3595 12.2326 18.0901 12.9368 18.2602C13.9378 18.5021 14.0499 18.1675 13.8266 17.2135C13.763 16.9418 13.6043 16.5073 13.7717 16.2541C13.8654 16.1123 14.0216 16.0774 14.334 16.0077C14.8268 15.8976 15.2332 15.7177 15.5177 15.4233C16.25 14.6658 16.25 13.4467 16.25 11.0084C16.25 8.57002 16.25 7.3509 15.5177 6.59342C14.7855 5.83594 13.607 5.83594 11.25 5.83594Z" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M7.91602 12.5C8.3911 13.006 9.14743 13.3333 9.99935 13.3333C10.8513 13.3333 11.6076 13.006 12.0827 12.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.50896 9.16406H7.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.509 9.16406H12.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_39_1896">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </div>


      <div title='History - Coming Soon' className="sidepane-button sidepane-button-disabled">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_39_1910)">
            <path d="M9.17143 17.5026H8.0039C5.01619 17.5026 3.52234 17.5026 2.59417 16.5568C1.66602 15.611 1.66602 14.0888 1.66602 11.0443C1.66602 7.99978 1.66602 6.47754 2.59417 5.53174C3.52234 4.58594 5.01619 4.58594 8.0039 4.58594H11.1728C14.1605 4.58594 15.6544 4.58594 16.5826 5.53174C17.2967 6.25943 17.4613 7.32835 17.4993 9.16927" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.709 15.7109L14.584 14.9609V13.0859M10.834 14.5859C10.834 16.657 12.5129 18.3359 14.584 18.3359C16.6551 18.3359 18.334 16.657 18.334 14.5859C18.334 12.5149 16.6551 10.8359 14.584 10.8359C12.5129 10.8359 10.834 12.5149 10.834 14.5859Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.334 4.58073L13.2512 4.32318C12.8387 3.0398 12.6324 2.39812 12.1414 2.03109C11.6503 1.66406 10.9982 1.66406 9.69365 1.66406H9.47432C8.16985 1.66406 7.51762 1.66406 7.02658 2.03109C6.53553 2.39812 6.32928 3.0398 5.91677 4.32318L5.83398 4.58073" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_39_1910">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </div>

      <div title='Collections - Coming Soon' className="sidepane-button sidepane-button-disabled">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.0847 5.10468C15 6.0453 15 7.5592 15 10.587V11.9078C15 14.9356 15 16.4495 14.0847 17.3901C13.1694 18.3307 11.6962 18.3307 8.75 18.3307C5.80372 18.3307 4.33058 18.3307 3.41529 17.3901C2.5 16.4495 2.5 14.9356 2.5 11.9078V10.587C2.5 7.5592 2.5 6.0453 3.41529 5.10468C4.33058 4.16406 5.80372 4.16406 8.75 4.16406C11.6962 4.16406 13.1694 4.16406 14.0847 5.10468Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.25 4.58594V8.64369C6.25 9.4231 6.25 9.81277 6.49152 9.95227C6.95921 10.2224 7.8365 9.32119 8.25313 9.04985C8.49475 8.89244 8.61558 8.81377 8.75 8.81377C8.88442 8.81377 9.00525 8.89244 9.24683 9.04985C9.6635 9.32119 10.5408 10.2224 11.0085 9.95227C11.25 9.81277 11.25 9.4231 11.25 8.64369V4.58594" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.5 1.66406H9.16667C13.095 1.66406 15.0592 1.66406 16.2796 2.88445C17.5 4.10484 17.5 6.06902 17.5 9.9974V14.9974" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>


    </div>
  );
});

export default QuerySidepane;
