import React, { useState } from 'react';
import './challenges.css';
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";


const ChallengeSolution = ({ solutionQuery, solutionText = undefined }) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsOpen(!isOpen);
  };
  
  return (
    <div className='challenge-solution-container my-2 '>
      <a href="#" onClick={handleClick} className='text-[#333] font-normal '>
        <span>{isOpen? <FiMinusSquare fontSize={22} className='inline text-center mb-[2px]'/> 
                     : <FiPlusSquare fontSize={22} className='inline text-center mb-[2px]' />}</span> See solution</a>
      {isOpen ? (<pre  ><code>{solutionQuery}</code></pre>) : (<span/>)}
      {isOpen && !!solutionText ? (<p className='p-3 bg-[#F9E4D9]'>{solutionText}</p>) : (<span/>)
      
      }
    </div>

  );
};

export default ChallengeSolution;
