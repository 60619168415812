import * as monaco from 'monaco-editor';

export function getIntellisenseSuggestions() {
  return [
    {
      label: 'SELECT',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'SELECT ',
      documentation: {
        value: [
          '**SELECT** statement',
          '',
          'Used to query and retrieve data from a database.',
          '',
          '**Example:**',
          '```sql',
          'SELECT column1, column2 FROM table_name;',
          '```'
        ].join('\n'),
      },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    {
      label: 'FROM',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'FROM ',
      documentation: {
        value: [
          '**FROM** clause',
          '',
          'Specifies the table from which to retrieve or delete data.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table_name;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'WHERE',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'WHERE ',
      documentation: {
        value: [
          '**WHERE** clause',
          '',
          'Filters records based on a specified condition.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table_name WHERE column1 = value;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'INSERT',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'INSERT INTO ',
      documentation: {
        value: [
          '**INSERT** statement',
          '',
          'Adds new rows of data to a specified table.',
          '',
          '**Example:**',
          '```sql',
          'INSERT INTO table_name (column1, column2) VALUES (value1, value2);',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'UPDATE',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'UPDATE ',
      documentation: {
        value: [
          '**UPDATE** statement',
          '',
          'Modifies existing records in a table.',
          '',
          '**Example:**',
          '```sql',
          'UPDATE table_name SET column1 = value WHERE condition;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'DELETE FROM',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'DELETE FROM ',
      documentation: {
        value: [
          '**DELETE** statement',
          '',
          'Removes records from a table.',
          '',
          '**Example:**',
          '```sql',
          'DELETE FROM table_name WHERE condition;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'JOIN',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'JOIN ',
      documentation: {
        value: [
          '**JOIN** clause',
          '',
          'Combines rows from two or more tables based on a related column between them.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table1 JOIN table2 ON table1.column = table2.column;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'INNER JOIN',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'INNER JOIN ',
      documentation: {
        value: [
          '**INNER JOIN**',
          '',
          'Returns records that have matching values in both tables.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table1 INNER JOIN table2 ON table1.column = table2.column;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'LEFT JOIN',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'LEFT JOIN ',
      documentation: {
        value: [
          '**LEFT JOIN**',
          '',
          'Returns all records from the left table and matched records from the right table. Returns NULL for unmatched rows in the right table.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table1 LEFT JOIN table2 ON table1.column = table2.column;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'RIGHT JOIN',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'RIGHT JOIN ',
      documentation: {
        value: [
          '**RIGHT JOIN**',
          '',
          'Returns all records from the right table and matched records from the left table. Returns NULL for unmatched rows in the left table.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table1 RIGHT JOIN table2 ON table1.column = table2.column;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'GROUP BY',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'GROUP BY ',
      documentation: {
        value: [
          '**GROUP BY** clause',
          '',
          'Groups rows that have the same values into summary rows, typically used with aggregate functions.',
          '',
          '**Example:**',
          '```sql',
          'SELECT column, COUNT(*) FROM table_name GROUP BY column;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'ORDER BY',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'ORDER BY ',
      documentation: {
        value: [
          '**ORDER BY** clause',
          '',
          'Sorts the result set in ascending or descending order.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table_name ORDER BY column ASC;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'LIMIT',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'LIMIT ',
      documentation: {
        value: [
          '**LIMIT** clause',
          '',
          'Specifies the number of records to return.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table_name LIMIT 10;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'OFFSET',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'OFFSET ',
      documentation: {
        value: [
          '**OFFSET** clause',
          '',
          'Specifies the number of rows to skip before starting to return rows in the result set.',
          '',
          '**Example:**',
          '```sql',
          'SELECT * FROM table_name LIMIT 10 OFFSET 5;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'WITH',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'WITH ',
      documentation: {
        value: [
          '**WITH** clause',
          '',
          'Defines a temporary named result set.',
          '',
          '**Example:**',
          '```sql',
          'WITH cte AS (SELECT * FROM table_name) SELECT * FROM cte;',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'JSONExtractString',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'JSONExtractString ',
      documentation: {
        value: [
          '**JSONExtractString** function',
          '',
          'Extracts a string from a JSON string given a field key or indices.',
          '',
          '**Example:**',
          '```sql',
          'SELECT JSONExtractString(\'{"a": "hello", "b": [-100, 200.0, 300]}\', \'a\')',
          '```'
        ].join('\n'),
      },
    },
    {
      label: 'JSONExtractInt',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'JSONExtractString ',
      documentation: {
        value: [
          '**JSONExtractInt** function',
          '',
          'Extracts an integer from a JSON string given a field key or indices.',
          '',
          '**Example:**',
          '```sql',
          'SELECT JSONExtractString(\'{"a": "hello", "b": 35}\', \'b\')',
          '```'
        ].join('\n'),
      },
    },

    // My own preprocessing
    {
      label: 'VISUALIZE TIMECHART',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'VISUALIZE TIMECHART',
      documentation: 'Visualizes a time chart',
    },
    {
      label: 'VISUALIZE PIECHART',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'VISUALIZE PIECHART',
      documentation: 'Visualizes a pie chart',
    },
    {
      label: 'VISUALIZE TABLE',
      kind: monaco.languages.CompletionItemKind.Keyword,
      insertText: 'VISUALIZE TABLE',
      documentation: 'Changes to table view',
    },

    // Tables
    {
      label: 'Logs (table)',
      kind: monaco.languages.CompletionItemKind.Variable,
      insertText: 'Logs ',
      documentation: '',
    },
  ];
}
