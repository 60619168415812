import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from 'firebase/auth';
import { FaChevronDown } from "react-icons/fa";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { auth } from './firebase';
import { AiOutlineLogout } from "react-icons/ai";

interface TopBarProps {
  user: User;
}

const TopBar = ({ user }: TopBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false); // Close the popup if clicked outside
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); 
    };
  }, [isOpen]);

  return (

    <div id="top-bar" className='h-[50px] bg-[#F9F9F9] flex flex-row justify-between items-center px-[24px] border-b border-[#DDDBDA]'>
      <div id="top-bar-title" className='font-semibold text-[25px]'>Query</div>
      <div id="profile" className="text-gray-700 flex items-center gap-1" >
        <FontAwesomeIcon icon={faUser} className="user-icon" />
        <span id="user-display-text" >{user.displayName ?? user.email}</span>
        <FaChevronDown fontSize={13} className='w-[22px] h-[22px] p-1 mt-[2px] hover:bg-gray-200 rounded-lg cursor-pointer' onClick={togglePopup} />
        {isOpen && (
          <div ref={popupRef} className="absolute top-12 right-0 w-64 p-4 bg-white border border-gray-300 rounded shadow-lg z-40">
            <div id="profile-menu-content flex items-center">
              <AiOutlineLogout className="mr-2 mb-[2px] inline" />
              <a href="#" onClick={() => auth.signOut()}>Logout</a>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}


export default TopBar;

{/* <div id="profile" className="text-white" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faUser} className="user-icon" />
          <span id="user-display-text" >{user.displayName ?? user.email}</span>
        </div> */}