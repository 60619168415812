import { User } from "firebase/auth";
import logo from './obics-logo-white-text.png';
import "./sidebar.css";
import SidebarLink from "./sidebar-link";
import { faCode, faChartSimple, faHistory, faBell, faCircleNodes, faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons'

interface SidebarProps {
  user: User;
  setIsProfileHovered: (isProfileHovered: boolean) => void;
}

const Sidebar = ({ user, setIsProfileHovered }: SidebarProps) => {



 
  return (
    <div id="sidebar">
      <div id="sidebar-top">

        <a href="https://obics.io" >
          <img
            id="sidebar-logo1"
            src={logo}
            width={120}
            alt="Obics logo"
            className="ml-[5px] mb-[30px]"
          />
        </a>

        {/* // TODO: Place on bottom */}
        <SidebarLink icon={faCode} link="/query" text="Query" active={true} enabled={true} />
        <SidebarLink icon={faHistory} link="/tail" text="Live Tail" active={false} enabled={false}/>
        <SidebarLink icon={faChartSimple} link="/dashboards" text="Dashboards" active={false} enabled={false} />
        <SidebarLink icon={faBell} link="/alerts" text="Alerts" active={false} enabled={false} />
        <SidebarLink icon={faCircleNodes} link="/service-map" text="Service Map" active={false} enabled={false} />
        <SidebarLink icon={faClipboardList} link="/issues" text="Issues" active={false} enabled={false} />
      </div>
      <div id="sidebar-bottom">
        
        
      </div>
    </div>

  );
}

export default Sidebar
