import React, { useState } from 'react';
import './challenges.css';
import Challenge1 from './challenge1';
import Challenge2 from './challenge2';
import Challenge3 from './challenge3';
import Challenge4 from './challenge4';
import Challenge5 from './challenge5';
import { LiaWindowMinimizeSolid } from "react-icons/lia";
import { LiaWindowMaximizeSolid } from "react-icons/lia";
import { CgMaximizeAlt } from "react-icons/cg";


const Challenges = (() => {

  const [challengeIndex, setChallengeIndex] = useState(1);
  const minChallenge = 1;
  const maxChallenge = 5;
  const handleNext = (event) => {
    event.preventDefault();
    setChallengeIndex(challengeIndex + 1);
  }

  const handlePrev = (event) => {
    event.preventDefault();
    setChallengeIndex(challengeIndex - 1);
  }

  const isNextEnabled = () => challengeIndex < maxChallenge;
  const isPrevEnabled = () => challengeIndex > minChallenge;

  function getChallengeTitle(): React.ReactNode {
    switch (challengeIndex) {
      case 1: return "Get Familiar";
      case 2: return "Log IDs";
      case 3: return "Timecharts";
      case 4: return "Piecharts";
      case 5: return "Joins";
      default: return "Unknown challenge";
    }
  }

  const [isMinimized, setIsMinimized] = useState(false);

  const handleMinimize = () => {
    setIsMinimized(true);
  };

  const handleRestore = () => {
    setIsMinimized(false);
  };


  return (
    <div>
      {isMinimized ? (
        <button className="minimized-popup w-[374px] h-[61px] text-[20px] px-[20px] flex justify-between items-center "
          onClick={handleRestore}
        >

          <span>Sandbox | {getChallengeTitle()}</span> <CgMaximizeAlt className='inline ' fontSize={26} />
        </button>
      ) : (
        <div className="challenge-container flex flex-col justify-between">
          <div id="challenge-top" className="bg-text1 rounded-t-2xl h-[60px] text-[22px] text-white px-4 flex items-center justify-between border border-x-1 border-t-1 border-b-0 border-gray-300">
            <h3>Sandbox {challengeIndex}/{maxChallenge}: {getChallengeTitle()}</h3>
            <button className="minimize-button tooltip-container" onClick={handleMinimize} title='Minimize' >
              <LiaWindowMinimizeSolid fontSize={26} />
            </button>
          </div>

          <div id="challenge-content-container" className='flex-1 overflow-auto p-3 bg-[#FAFAF8] border border-x-1 border-y-0 border-gray-300' > {/* border border-y-0 border-l-1 border-l-gray-100 */}
            {
              challengeIndex === 1 ? (<Challenge1 />)
                : (challengeIndex === 2 ? <Challenge2 />
                  : (challengeIndex === 3 ? <Challenge3 />
                    : (challengeIndex === 4 ? <Challenge4 /> : <Challenge5 />)))
            }
          </div>

          <div className="challenge-footer bg-[#FAFAF8] flex items-center p-5 rounded-2xl rounded-t-none border border-x-1 border-t-0 border-gray-300">
            <span></span>
            <div className="challenge-nav-container">
              <button
                className="bg-bgdark2 w-[92px] h-[40px] text-white rounded-3xl shadow hover:bg-black transition flex items-center justify-center text-[16px] font-[400]"
                id="prevChallenge"
                onClick={handlePrev}
                disabled={!isPrevEnabled()}>
                Previous
              </button>
              <button
                className="bg-bgdark2 w-[92px] h-[40px] text-white rounded-3xl shadow hover:bg-black transition flex items-center justify-center text-[16px] font-[400]"
                onClick={handleNext}
                disabled={!isNextEnabled()}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>


  );
});

export default Challenges;
